import React from 'react';
import { Link } from "gatsby";
import './WeaverProduct.scss';

const WeaverProduct = ({name, prodImg, to}) => {
    return (
      <Link 
        key={name} 
        className="productItem" 
        to={to} 
        >
        <img 
          src={prodImg}
          alt={name}
          />

        <p>{name}</p>
      </Link>
    );
}

export default WeaverProduct;
