import React from "react";
import Layout from "../components/Layout/Layout";
import WaverChickenGrid from '../components/Grid/WaverChickenGrid';
import WeaverProduct from '../components/Products/WeaverProduct';
import './products.scss';
import { productsData as products} from '../data/products';

const Products = (props) => {
  return (
    <Layout
      metaTitle="Frozen Breaded Chicken for Your Family"
      metaDescription=""
      searchTitle="Products"
    >
      <WaverChickenGrid>
        <p>
          <b>Products</b>
        </p>

        <div className="productList">
            {
              products.map((product) => (
                <WeaverProduct 
                  key={product.id}
                  name={product.title}
                  prodImg={product.image}
                  to={product.path}
                />
              ))
            }
        </div>
      </WaverChickenGrid>
    </Layout>
  );
}

export default Products;
