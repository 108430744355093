import ChickenBreastFunNugget from '../img/products/15845-964.jpg';
import ChickenBreastNuggets from '../img/products/15846-964.jpg';
import ChickenBreastPatties from '../img/products/15847-964.jpg';
import ChickenBreastTenders from '../img/products/15773-964.jpg';

export const productsData = [
  {
    id: 0,
    path: "chicken-breast-fun-nugget/",
    title: "Chicken Breast Fun Nugget",
    image: ChickenBreastFunNugget,
    imageAlt: "Chicken Breast Fun Nugget"
  },
  {
    id: 1,
    path: "chicken-breast-nuggets/",
    title: "Chicken Breast Nuggets",
    image: ChickenBreastNuggets,
    imageAlt: "Chicken Breast Nuggets"
  },
  {
    id: 2,
    path: "chicken-breast-patties/",
    title: "Chicken Breast Patties",
    image: ChickenBreastPatties,
    imageAlt: "Chicken Breast Patties"
  },
  {
    id: 3,
    path: "chicken-breast-tenders/",
    title: "Chicken Breast Tenders",
    image: ChickenBreastTenders,
    imageAlt: "Chicken Breast Tenders"
  }
];